<template>
<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div>
    <b-tabs justified>
      <b-tab title="Expression">
        <b-container fluid>
          <logic-expression
            v-if="variables"
            :variables="variables"
            v-model="script"
          />
        </b-container>
      </b-tab>
      <b-tab title="Agents">
        <actions-reorder-list
          :key="getID(`actions-reorder-list-${version}`)"
          class="mx-1"
          :middlewareID="middlewareID"
          v-model="agents"
          @agentAdded="version++"
          @agentEdited="version++"
          @agentDeleted="version++"
        />
      </b-tab>
    </b-tabs>
  </div>
</middleware-sidebar-outline>
</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import LogicExpression from '../Comparison/LogicExpression.vue'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import Source from '@/custom/class/Agent/Source'
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'


  export default {
      mixins: [MiddlewaresMixin],
      components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      LogicExpression,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BAvatar,
      BCardBody,
      ActionsReorderList,
      MiddlewareSidebarOutline
    },
    props: {
      i18nKey: {
        type: String,
        default: "continue"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: undefined,
        middlewareType: new Middlewares().items.find(el=> el.id == 23),
        variant_map: undefined,
        script: '',
        logicalExpression: null,
        agentList: [],
        isSaving: false,
        version: 0,

        defaultAgentActionID: 32, 
        defaultAgentBlockID: 1
      }
    },
    computed: {
      agents: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.initializeAgents()
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      isObject(item) {
        return item instanceof Object && !(item instanceof Array);
      },
      isArray(item) {
        return item instanceof Array;
      },
      buildAgents() {
        // if (!this.script || !JSON.parse(this.script).$OR[0].$AND.length) {
        //   this.$emit('saved', [])
        //   return
        // }
        this.isSaving = true;
        
        this.agentList.push(this.defineLogicExpression());

        this.saveAgents();
      },
      defineLogicExpression() {
        return {
          agent_id: this.logicalExpression.id || null,
          middleware: this.middlewareID,
          enum_agent_action: 32,
          enum_agent_block_id: 1,
          enum_source_destiny_id: null,
          destiny_value: null,
          enum_source_register_1: 7,
          register_1_value: this.script,
          enum_source_register_2: null,
          register_2_value: null,
          fatal_on_fail: true,
        };
      },
      saveAgents() {
        this.$store
        .dispatch("saveAgents", {
          agentList: this.agentList,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          makeToast({
            title: this.$t("agent.toast.create_agents.success.title"),
            text: this.$t("agent.toast.create_agents.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });

          this.$emit("saved", response.data);
          this.isSaving = false
        })
        .catch((error) => {
          console.log(error);
          this.isSaving = true
        });
      },
      initializeAgents() {
        this.logicalExpression = MiddlewareFunctions.getValueFrom(
          {
            default: DefaultAgent.setAgent(this, {
              id: null,
              enum_agent_action_id: this.defaultAgentActionID,
              enum_agent_block_id: this.defaultAgentBlockID,
              register_1: {
                source: "7",
              },
              register_2: {
                source: 7,
              },
              register_destiny: {
                source: null,
                value: null,
              },
              execution_order: 1,
              fatal_on_fail: true,
            }),
            source: "7",
            type: this.defaultAgentActionID,
            from: {
              source: "register_1",
            },
            modifier: false,
            multiple: false,
          },
          this.agents
        );

        if (this.logicalExpression && this.logicalExpression.register_1.value) {
          this.script = this.logicalExpression.register_1.value || ''
        }
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
      getSourceLabel(id) {
        const source = new Source(id);
        return this.$t(source.label);
      }
    }

  }
</script>

<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>



<style lang="scss" scoped>
.agent-text {
  display: block !important;
  width: 300px;
}
</style>
<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}

</style>